import React from "react"

const AboutusPage = () => {
    return (
        <>
                <section className="windows-host">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-6 col-12">
                            <h1>About us</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="abouts-block bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-12" >
                           
                            Mothersoft Technologies is an Indian based IT Solution Provider. It was founded by a group of young and creative software engineers in year 2009. We developed web based applications. We have extended our services and developments into various IT development areas. 
                            Mothersoft Technologies is an offshore web development company based in Chennai, India. We mainly focused on developing user-friendly websites for clients from various domains with highly scalable business solutions and services. We follow innovative approaches and advanced methodologies to all our services. We provide strategic development for the global business with wide array of solutions and services which helps for a range of key verticals. 
                            We can help you with our unique approach of designing business and customer oriented web resources that makes your website special one out of the millions. The websites developed by our team possess impressive looks and also it highly aims to create an effective online presence which results in the best image and identity of your business. 


                            Over a decade, the endeavor of our company is to present unremitting support and assistance to our valued Customers/Clients. With lucid
                            cogitation on Information Management, we provide eminence in software solution to the clients. We are proficient in the utilization of technology in favor of Desktop Application,
                            Web Application and Tailor-made Products in a lucrative yet value-maximizing manner.

                        </div>
                        <div className="col-12 col-lg-12" >
                            VISION
                            To be acknowledged as a web designing and development company that strives in
                            delivering outstanding e-business solution contrived with innovative thoughts and
                            quality, we assist our progressive customers to exceed their expectations.
 </div>
                        <div className="col-12 col-lg-12" >
MISSION
                       
                            To strive hard to provide services as per our valuable customer’s requirement
                            employing state-of-art technology in a cost effective manner.
 </div>
                        <div className="col-12 col-lg-12" >
QUALITY POLICY
We shall provide quality products and services to our treasured customers keeping the
credibility in the market for the quality of our products along with total customer
satisfaction all the time.
</div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutusPage
