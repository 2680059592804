import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import AboutusPage from "../components/aboutus/index"
const aboutus = () => {
  return (
    <Layout>
      <Seo
        title="Linux and Windows Reseller Hosting - Mothersoft"
        desc="Mothersoft Provides both Linux and windows reseller hosting with many features"
        pathname="/reseller/"
        keywords="linux reseller hosting, windows reseller hosting, reseller hosting"
      />
      <AboutusPage />
    </Layout>
  )
}

export default aboutus
